<template>
  <div class="list-content-page">
    <div class="ym-title">检疫信息</div>
    <!-- 条件筛选 -->
    <div class="ym-panel-general">
      <el-form
        class="fl"
        :inline="true"
        :model="form"
      >
        <el-form-item prop="productName" label="产品名称：">
          <el-input
            v-model="form.productName"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          class="ym-input-left"
          prop="batchNum"
          label="产品批次编号："
        >
          <el-input
            v-model="form.batchNum"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
         <el-button class="ym-input-left" type="primary" round size="medium" @click="submit">查询</el-button>
         <el-button plain round size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym-panel-table">
      <!-- 操作按钮 -->
      <div class="btns">
        <el-button
          type="primary"
          round
          icon="el-icon-plus"
          size="medium"
          @click="toAdd"
          >新增
        </el-button>
        <el-button plain round size="medium" @click="exportFileConfirm">
          <i class="iconfont iconxiazai"></i>
          下载检疫报告
        </el-button>
      </div>
      <!--表格-->
      <el-table
        :data="tableData"
        v-loading="loading"
        highlight-current-row
        class="ym-table"
        size="mini"
        stripe
        border
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '0px' }"
        @selection-change="onSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batchNum"
          label="产品批次编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="aqCompanyName"
          label="检测机构"
          min-width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="aqFlag"
          label="是否合格"
          min-width="80"
          align="center"
        >
        <template slot-scope="scope">
          <span v-if="scope.row.aqFlag==1">是</span>
          <span v-else>否</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="aqDate"
          label="检疫日期"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            <i v-if="scope.row.aqDate" class="el-icon-date"></i>
            <span style="margin-left: 6px">{{ scope.row.aqDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="files" label="附件" width="260" align="center">
          <template slot-scope="scope">
            <div class="fujian-content">
            <el-link v-for="(item, index) in scope.row.files" :key="index" :underline="false" :href="item.fileUrl" target="_blank">
              <div class="ym-limit-content download-title">{{ item.originalName}}</div>
            </el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="afcBatchAqId"
          align="center"
          label="操作"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.chainFlag===1">
                <el-button type="text" @click="toSave(scope.row)">编辑</el-button>
                <el-button type="text" @click="deleteTable(scope.row)">删除</el-button>
            </template>
            <el-button type="text" @click="toGet(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      
      <!-- 询问导出检疫当前页或者全部弹窗 -->
     <el-dialog
      title="导出"
      :visible="dialogVisible"
      append-to-body
      @close="onDialogClose"
      width="580px">
      <div class="ex-qa-file-dialog-body">
        <el-radio-group el-radio-group v-model="templateRadio">
          <el-radio :label="1">当前页</el-radio>
          <el-radio :label="2">全部</el-radio>
        </el-radio-group>
      </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible=false;">取 消</el-button>
          <el-button type="primary" @click="submitConfirm()">确 定</el-button>
        </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { saveFileToLink } from 'web-downloadfile'

export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      afcBatchAqIds: [], // 选中的检疫id
      dialogVisible: false, // 询问导出弹窗
      templateRadio: 1, // 1下载当前页，2下载全部
      QAfileKey: '', // 查询导出文件key
      QAInterval: null, // 导出下载文件轮询定时器
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 40
      },
      form: {
        productName: '',
        batchNum: ''
      }
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    toAdd() {
      this.$router.push({ path: '/listQuarantines/saveQuarantine' }).catch(err => err)
    },
    /** 表格多选变化 */
    onSelectionChange(selection) {
      this.afcBatchAqIds = selection.map(item => item.afcBatchAqId)
    },
    onDialogClose() {
      this.dialogVisible = false
    },
    exportFileConfirm() {
      // 如果在第一列中有勾选，则直接导出已勾选项
      if (this.afcBatchAqIds && this.afcBatchAqIds.length > 0) {
        // 导出二维码
        this.exportFile(3) // 导出页数标识 1=当前页 2=全部数据 3=选中数据
      } else {
        // 如果无勾选情况，则弹出弹窗，确认是导出当前页还是全部
        this.dialogVisible = true
        // this.afcBatchAqIds = ''
      }
    },
    submitConfirm() {
      this.exportFile(this.templateRadio)
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    toGet(row) {
      this.$router.push({ path: '/listQuarantines/getQuarantine', query: { id: row.afcBatchAqId }})
    },
    toSave(row) {
      this.$router.push({ path: '/listQuarantines/saveQuarantine', query: { id: row.afcBatchAqId }})
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getList()
    },
    // 条件搜索
    submit() {
      this.getList()
    },
    // 条件重置
    reset() {
      this.form.batchNum = ''
      this.form.productName = ''
      this.getList()
    },
    // 删除列表
    deleteTable(row) {
      // 确认删除操作
      this.$confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作
        this.remove(row.afcBatchAqId)
      }).catch(() => {
      })
    },
    async getList() {
      const params = {
        batchNum: this.form.batchNum,
        productName: this.form.productName,
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      this.loading = true
      const { data } = await this.$http.quarantinesInfor.listQuarantines(params)
      if (data.code === '0') {
        const records = data.data.records
        var array = []
        for (var item of records) {
          var obj = {}
          obj.afcBatchAqId = item.afcBatchAqId
          obj.aqFlag = item.aqFlag
          obj.batchNum = item.batchNum
          obj.productName = item.productName
          obj.aqCompanyName = item.aqCompanyName
          obj.files = item.files
          obj.aqDate = item.aqDate.substr(0, 10)
          obj.chainFlag = item.chainFlag
          array.push(obj)
        }
        this.tableData = array
        console.log('列表：', this.tableData)
        this.page.total = parseInt(data.data.total)
      } else {
        this.$message.error(data.msg || '获取列表失败')
      }
      this.loading = false
    },
    // 删除
    async remove(afcBatchAqId) {
      const params = {
        afcBatchAqId: afcBatchAqId
      }
      this.loading = true
      const { data } = await this.$http.quarantinesInfor.removeQuarantine(params)
      if (data.code !== '0') return this.$message.error(data.msg || '删除失败')
      else {
        this.$message.success(data.msg || '删除成功')
        if (this.tableData.length === 1) {
          this.page.currentPage -= this.page.currentPage
          this.getList()
        } else {
          this.getList()
        }
        this.loading = false
      }
    },
    /** 下载检疫报告 */
    async exportFile(pageFlag) {
      this.dialogVisible = false
      const params = {
        // afcBatchId: this.id,
        pageFlag: pageFlag,
        current: this.page.currentPage,
        size: this.page.pageSize,
        afcBatchAqIds: this.afcBatchAqIds
      }
      this.loading = true
      const { data } = await this.$http.quarantinesInfor.downloadQuarantineReport(params)
      if (data.code === '0') {
        this.QAfileKey = data.data.fileKey
        if (this.QAInterval) {
          clearInterval(this.QAInterval)
        }
        this.QAInterval = setInterval(this.getExportFile, 1500)
      } else {
        this.$message.error(data.msg || '操作失败')
      }
      this.loading = false
    },
    /** 查询下载检疫报告结果 */
    async getExportFile() {
      const params = {
        fileKey: this.QAfileKey
      }
      const { data } = await this.$http.quarantinesInfor.getDownloadQuarantineReport(params)
      if (data.code === '0') {
        clearInterval(this.QAInterval)
        this.QRInterval = null
        this.$message.success('导出成功')
        const suffix = data.data.fileUrl.split('.').pop()
        saveFileToLink(data.data.fileUrl, '检疫报告', suffix)
      } else if (data.code === 'B01104' || data.code === 'B01103') {
        clearInterval(this.QAInterval)
        this.QRInterval = null
        this.$message.error(data.msg || '操作失败')
      }
    }
  }
}
</script>

<style lang="scss" scrope>
.list-content-page {
  .el-form-item {
    margin-bottom: 0;
  }
  .ym-panel-table {
    .icon-btn {
      font-size: 12px;
      color: #3e6df5;
      margin-right: 4px;
    }
  }
  .btns {
    text-align: right;
  }
  .ym-table {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  //按钮图标样式调整
  .iconfont {
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .fujian-content{
    display: flex;
    flex-direction: column;
  }
  //下载文件样式
  .download-title{
    color:#3e6df5;
  }
  .download-title:hover{
    color:#668bfa;
    text-decoration: underline;
  }
}
</style>
<style>
.ex-qa-file-dialog-body{
  padding: 20px 20px;
}
</style>